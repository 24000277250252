<template>
    <error-toast :errorMessage="errorMessage" @resetErroMessage="errorMessage = null">
        <template v-slot:error-message>
            <span class="pl-2 text-md">{{ errorMessage }}</span>
        </template>
    </error-toast>
    <LoadingSpinner v-if="!vendors" />
    <main v-if="vendors && vendors.length" @click="eventDeligate" class="mx-32 my-10 min-h-screen">
        <section class="flex items-center justify-between px-7 py-4 bg-white rounded shadow">
            <h1 class="text-xl font-semibold">Vendors List</h1>
            <div class="flex flex-row-reverse items-center text-sm">
                <router-link
                    :to="{ name: 'newVendor' }"
                    class="inline-block px-4 text-center mx-2 py-2 bg-primaryColor rounded text-white capitalize"
                    >create new vendor</router-link
                >
                <div class="mr-16 relative">
                    <div
                        @click="showStatusOptions = !showStatusOptions"
                        data-statuscontainer="statuscontainer"
                        class="flex items-center border border-borderColor rounded px-3.5 py-2"
                    >
                        <span data-statuscontainer="statuscontainer">Sort by:</span>
                        <span
                            v-if="!selectedStatus"
                            data-statuscontainer="statuscontainer"
                            class="w-16 uppercase px-1"
                            >All</span
                        >
                        <span
                            v-else
                            data-statuscontainer="statuscontainer"
                            class="w-16 uppercase px-1 text-xs"
                            >{{ selectedStatus }}</span
                        >
                        <img
                            src="@/assets/images/chevron-down-icon-grey.svg"
                            data-statuscontainer="statuscontainer"
                        />
                    </div>
                    <ul
                        v-if="showStatusOptions"
                        class="absolute top-full bg-white shadow-md w-full py-2"
                    >
                        <li
                            @click="selectStatus(status)"
                            v-for="(status, index) in statuses"
                            :key="index"
                            :data-status="status"
                            class="px-2 py-1 hover:bg-borderColor cursor-pointer capitalize"
                        >
                            {{ status }}
                        </li>
                    </ul>
                </div>
                <div class="mr-4">
                    <input
                        type="text"
                        placeholder="Search..."
                        class="w-56 border border-borderColor rounded p-2"
                    />
                    <button
                        class="inline-block px-6 text-center mx-2 py-2 bg-alternativeBgColor rounded text-white capitalize"
                    >
                        search
                    </button>
                </div>
            </div>
        </section>
        <section class="my-6 px-7 py-4 bg-white rounded shadow">
            <div
                class="expenditure-items-wrapper flex items-center justify-between uppercase text-xs text-alternativeBgColor px-6 tracking-widest font-bold"
            >
                <div style="width: 25%" class="flex items-center px-2">
                    <input type="checkbox" class="mr-3 align-text-bottom" />name
                </div>
                <div class="px-2">phone</div>
                <div>purchases</div>
                <div class="px-2">status</div>
                <img
                    src="@/assets/images/eye-icon.svg"
                    alt="eye icon"
                    style="visibility: hidden"
                    class="mr-6"
                />
            </div>
            <div
                v-for="vendor in vendors"
                :key="vendor.id"
                class="expenditure-items-wrapper flex items-center justify-between capitalize my-2.5 py-4 px-6 border border-borderColor rounded text-sm"
            >
                <div style="width: 25%" class="flex items-center px-2">
                    <input type="checkbox" class="mr-3 align-text-bottom" />{{
                        vendor.vendor_details.name
                    }}
                </div>
                <div class="px-2">{{ vendor.phone }}</div>
                <div class="px-2">no data</div>
                <div>
                    <div
                        class="w-24 text-center uppercase font-bold py-1 px-4 rounded-full text-white text-xs bg-pillApprovedStatus"
                    >
                        no data
                    </div>
                    <!-- <div v-if="request.status === 'rejected'" class="w-24 text-center uppercase font-bold py-1 px-4 rounded-full text-white text-xs bg-pillRejectedStatus">
            {{ request.status }}
          </div> -->
                </div>
                <router-link :to="{ name: 'vendorView', params: { vendorId: vendor.id } }">
                    <img
                        src="@/assets/images/eye-icon.svg"
                        alt="eye icon"
                        class="cursor-pointer w-10 h-10 mr-6 bg-interactionBg rounded-full p-2"
                    />
                </router-link>
            </div>
        </section>
    </main>
    <empty-page v-if="vendors && !vendors.length" :routeName="routeName">
        <template v-slot:message>
            No vendors found, click on the button below to create a vendor.
        </template>
        <template v-slot:button-text>
            Create New Vendor
        </template>
    </empty-page>
</template>

<script>
import Request from '@/services/requestHelper'
import errorFunc from '@/util/error'

export default {
    name: 'vendorsHome',
    async mounted() {
        try {
            const { data } = await Request.getRequest('vendor')
            this.vendors = data.data.data
            console.log(this.vendors)
        } catch (error) {
            errorFunc(error.response, this.$router)
            this.errorMessage = error.response.data.message
        }
    },
    data() {
        return {
            vendors: null,
            statuses: ['active', 'inactive'],
            showStatusOptions: false,
            selectedStatus: null,
            routeName: 'newVendor',
            errorMessage: null,
        }
    },
    methods: {
        selectStatus(status) {
            this.selectedStatus = status
            this.showStatusOptions = false
        },
        eventDeligate(event) {
            if (!event.target.dataset.hasOwnProperty('statuscontainer')) {
                this.showStatusOptions = false
            }
        },
    },
}
</script>

<style scoped>
.expenditure-items-wrapper > div {
    width: calc(100% / 5);
}
</style>
